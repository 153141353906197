import 'regenerator-runtime/runtime'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { App } from './app/App'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import './index.css'
import { getSentryConfig, getHeapConfig } from 'do-cornucopia-root'

import { amplitudeAnalyticsAnalytics as createAmplitudeHeapToastAnalytics } from '@toasttab/do-secundo-amplitude-and-heap-analytics'
import envFromHostname from '@toasttab/env-from-hostname'

const tempWindow: any = window

const env = envFromHostname(window.location.hostname)

const amplitudeApiKey =
  env === 'prod'
    ? 'd2c74425e0f21e96d41c9dc5f5c8ffbe'
    : '4f931568751686a8bd7c64f90c5812b6'

const heapId = getHeapConfig(tempWindow.OO_GLOBALS.mode)

const amplitudeHeapAnalytics = createAmplitudeHeapToastAnalytics(
  amplitudeApiKey,
  {
    /**
     * Since we are loading the heap ID via do-cornucopia-root,
     * we do not need env-specific heap identifiers.
     */
    inputHeapIDs: {
      DEV: heapId,
      PREPROD: heapId,
      PROD: heapId
    },
    inputConfigOptions: {},
    inputLoadedCb() {
      console.log('Heap loaded')
    }
  }
)

amplitudeHeapAnalytics.load()

tempWindow.toastAnalytics = amplitudeHeapAnalytics

const sentry = getSentryConfig(tempWindow.OO_GLOBALS.mode)

const mountPoint = 'single-spa-application:root'
const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  cssScope: 'corn-layout-spa',
  rootComponent: App,
  sentry,
  domElementGetter: () => {
    const el = document.getElementById(mountPoint)
    if (!el) {
      throw new Error(
        `${mountPoint} does not exist, it is required for layout spas`
      )
    }
    return el
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
